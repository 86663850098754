export default function Privacy() {
  return (
    <main
      style={{
        fontFamily: 'Roboto',
        color: '#E2FFFF',
        padding: '0px 20px',
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy applies from June 7, 2022. If this privacy policy
        effective date is set in the future, a link will be provided to the
        current applicable privacy policy.
      </p>
      <p>Effective Date: April 28, 2022</p>
      <ol>
        <li>
          <p>What This Privacy Policy Covers</p>
        </li>
        <li>
          <p>Data Collection</p>
        </li>
        <li>
          <p>How We Use Your Information</p>
        </li>
        <li>
          <p>How We Share Information</p>
        </li>
        <li>
          <p>Privacy of Minors</p>
        </li>
        <li>
          <p>Your Rights</p>
        </li>
        <li>
          <p>Changes To Our Privacy Policy</p>
        </li>
        <li>
          <p>Contact Us</p>
        </li>
      </ol>
      <h2>1. What This Privacy Policy Covers</h2>
      <p>
        This Privacy Policy applies to our websites, apps and other services we
        operate.
      </p>
      <h2>2. Data Collection</h2>
      <p>
        In order for our platform to function properly, we need some information
        from you.
      </p>
      <h3>Your Information Shared With Us</h3>
      <p>
        When you use our services, you provide us with the following information
        when using our app:
      </p>
      <ul>
        <li>
          <p>Information you provide to us when you register with mUnity:</p>
          <ul>
            <li>
              <p>
                Telephone Number - We use your telephone number to identify you
                and provide mUnity services to you. &nbsp;This information is
                saved in our database. This will also be used to send an SMS
                confirmation code.
              </p>
            </li>
            <li>
              <p>
                E-mail Address - (optional) We use your e-mail address to
                identify you and provide mUnity services to you. &nbsp;This
                information is saved in our database.
              </p>
            </li>
            <li>
              <p>
                Interests/Tags - Tags/Interests you specify are used to match
                your interests with available nearby events. &nbsp;This
                information is saved in our database.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            Information you provide when you create an event that will be shared
            with others:
          </p>
          <ul>
            <li>
              <p>
                Location - We use your location to activate a geofence around
                this location that other users will interact with when they come
                into proximity to the location. &nbsp;This information is saved
                in our database.
              </p>
            </li>
            <li>
              <p>
                Event Details - A title and information you assign for the event
                details. This information is saved in our database.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            Information you provide that will be shared when you connect with
            another user:
          </p>
          <ul>
            <li>
              <p>
                Event Chat - Any messages you provide in the chat will be
                delivered to the target user via our services.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            Information you provide when you run the mUnity application in the
            background or foreground:
          </p>
          <ul>
            <li>
              <p>
                Background Location - We use your current location to provide
                nearby events in proximity to your current location that you may
                be interested in. This is toggleable on and off within the
                application. We do not save this information to our database.
              </p>
            </li>
            <li>
              <p>
                Background Location - We use your current location to provide
                other users that you specify your current location. This is
                toggleable on and off within the application. We do not save
                this information to our database.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>Information mUnity collects about your device:</p>
          <ul>
            <li>
              <p>
                Hardware &amp; Network Information - We use information about
                your device to debug, optimize and improve our services and user
                experience for your device and configuration. This information
                is saved in our database.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>Information others share with you:</p>
          <ul>
            <li>
              <p>
                Telephone Number - When you initiate a connection with another
                user, your telephone number may be shared with that user.
              </p>
            </li>
            <li>
              <p>
                Event Location - When you come in proximity to a nearby event
                that another user has created that matches your interests
              </p>
            </li>
            <li>
              <p>
                Event Information - A title and description with the event you
                are matched with.
              </p>
            </li>
            <li>
              <p>
                Event Chat - Any messages the user provides in the chat will be
                delivered to you via our services.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h2>3. How We Use Your Information</h2>
      <p>
        Our primary goal in using your information is to provide and improve our
        services to you. &nbsp;These may fall under the following:
      </p>
      <ul>
        <li>
          <p>Provide our services to you</p>
          <ul>
            <li>
              <p>Create and manage your account</p>
            </li>
            <li>
              <p>Communicate with you about our services</p>
            </li>
          </ul>
        </li>
        <li>
          <p>Assist you in connecting with other users</p>
          <ul>
            <li>
              <p>
                Provide recommendations to other users to join in meeting up
              </p>
            </li>
            <li>
              <p>Link two users together to facilitate said meet up</p>
            </li>
          </ul>
        </li>
        <li>
          <p>Improve and develop new services</p>
          <ul>
            <li>
              <p>
                Review interactions to streamline the design and functionality
                of the mUnity platform.
              </p>
            </li>
            <li>
              <p>
                Understand how users interact with and use our services to
                improve them.
              </p>
            </li>
            <li>
              <p>Develop new features and services.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>To prevent illegal or unauthorized activities</p>
        </li>
        <li>
          <p>To ensure legal compliance</p>
        </li>
      </ul>
      <h2>4. How We Share Information</h2>
      <p>
        mUnity thrives on making connections between our users. &nbsp;We also
        share some information with our service providers.
      </p>
      <h3>With Other mUnity Users</h3>
      <p>
        When you pulse or make an interaction public to other users, that
        information is shared with those users. &nbsp;These users can see the
        following:
      </p>
      <ul>
        <li>
          <p>a location that you choose to share with others</p>
        </li>
        <li>
          <p>a date/time of the invite</p>
        </li>
        <li>
          <p>your contact information if you choose to join an event</p>
        </li>
        <li>
          <p>your contact information if you choose to host an event</p>
        </li>
      </ul>
      <h3>With Our Service Providers</h3>
      <p>
        We use third-party services to help us operate and improve our services.
        &nbsp;These services aid us with a variety of purposes such as data and
        server hosting, debugging, analytics and security operations.
      </p>
      <h3>With Law Enforcement</h3>
      <p>We may disclose your information if reasonably necessary:</p>
      <ul>
        <li>
          <p>
            to comply with a legal process, such as a court order, subpoena or
            search warrant, government / law enforcement investigation or other
            legal requirements.
          </p>
        </li>
        <li>
          <p>
            to assist in the prevention or detection of crime (subject in each
            case to applicable law).
          </p>
        </li>
        <li>
          <p>to protect the safety of any person.</p>
        </li>
      </ul>
      <h2>5. Privacy of Minors</h2>
      <p>
        mUnity defines a Minor as being an individual of less than 18 years of
        age. Our services are restricted to individuals who are 18 years of age
        or older. &nbsp;We do not permit individuals under the age of 18 on our
        platform.
      </p>
      <h2>6. Your Rights</h2>
      <p>
        At mUnity, your privacy is important to us. &nbsp;You have the following
        rights available to you:
      </p>
      <ul>
        <li>
          <p>
            Device Permissions - Mobile devices can have permission management
            systems for specific types of device data and notifications.
            &nbsp;You can alter these settings to control how your device
            interacts with our service. &nbsp;Please note that removing some
            permissions may cause portions of our service to lose functionality.
          </p>
        </li>
        <li>
          <p>
            Uninstall - You can uninstall the app to stop all current and future
            information collection using the uninstall process for your device.
          </p>
        </li>
      </ul>
      <h2>7. Changes To Our Privacy Policy</h2>
      <p>
        We always try to make sure our privacy policy and data practices are up
        to date. &nbsp;As a result, this policy may change over time.
      </p>
      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or need to contact us about the privacy
        policy, please email us at{' '}
        <a
          aria-expanded="false"
          href="mailto:munity-dev@18techs.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          munity-dev@18techs.com
        </a>
        &nbsp; or you may mail/post your inquiry to the following address:
      </p>
      <p>Attn: mUnity Privacy</p>
      <p>
        180 Prospect Place
        <br />
        Alpharetta, GA 30005
        <br />
        United States
      </p>
      <br />
      <br />
    </main>
  );
}
