export default function TOS() {
  return (
    <main
      style={{
        fontFamily: 'Roboto',
        color: '#E2FFFF',
        padding: '0px 20px',
      }}
    >
      <h2>1. Acceptance of Terms of Use Agreement.</h2>
      <p>
        By creating a mUnity account or by using any mUnity service, whether
        through a mobile device, mobile application or computer (collectively,
        the “Service”) you agree to be bound by (i) these Terms of Use, (ii) our
        Privacy Policy, Cookie Policy, Safety Tips, and Community Guidelines,
        each of which is incorporated by reference into this Agreement, and
        (iii) any terms disclosed to you if you purchase or have purchased
        additional features, products or services we offer on the Service
        (collectively, this “Agreement”). If you do not accept and agree to be
        bound by all of the terms of this Agreement, you should not access or
        use the Service.
      </p>

      <p>
        We may make changes to this Agreement and to the Service from time to
        time. We may do this for a variety of reasons including to reflect
        changes in or requirements of the law, new features, or changes in
        business practices. The most recent version of this Agreement will be
        available on <a href="http://munity.us/tos">munity.us/tos</a>, and you
        should regularly check for the most recent version. The most recent
        version is the version that applies. If the changes include material
        changes to your rights or obligations, we will notify you in advance of
        the changes (unless we’re unable to do so under applicable law) by
        reasonable means, which could include notification through the Service
        or via email. If you continue to use the Service after the changes
        become effective, then you agree to the revised Agreement. You agree
        that this Agreement shall supersede any prior agreements (except as
        specifically stated herein), and shall govern your entire relationship
        with mUnity, including but not limited to events, agreements, and
        conduct preceding your acceptance of this Agreement.
      </p>

      <h2>2. Eligibility.</h2>

      <p>
        You must be at least 18 years of age to create an account on mUnity and
        use the Service. By creating an account and using the Service, you
        represent and warrant that you:
      </p>

      <ul>
        <li> you can form a binding contract with mUnity</li>
        <li>
          you are not a person who is barred from using the Service under the
          laws of the United States or any other applicable jurisdiction
        </li>
        <li>
          you will comply with this Agreement and all applicable local, state,
          national and international laws, rules and regulations, including
          without limitation, privacy laws, intellectual property laws,
          anti-spam laws, and regulatory requirements
        </li>
        <li> you will use the latest version of the Service</li>
        <li> you do not have more than one account on the Service</li>
        <li>
          you have not previously been removed from the Service by us, unless
          you have our express written permission to create a new account
        </li>
      </ul>

      <p>
        If at any time you cease to meet these requirements, all authorization
        to access our Service or systems is automatically revoked, and we will
        immediately delete your account.
      </p>
      <h2>3. Your Account.</h2>
      <p>
        In order to use mUnity, you may sign in using your telephone number. For
        more information regarding the information we collect from you and how
        we use it, please consult our
        <a href="https://munity.us/privacy">Privacy Policy</a>.
      </p>

      <p>
        You are responsible for maintaining the confidentiality of your login
        credentials you use to sign up for mUnity, and you are solely
        responsible for all activities that occur under those credentials. If
        you think someone has gained access to your account, please immediately
        contact us.
      </p>
      <h2>4. Modifying the Service and Termination.</h2>
      <p>
        mUnity may add new product features or enhancements from time to time as
        well as remove some features, and if these actions do not materially
        affect your rights or obligations, we may not provide you with notice
        before taking them. We may even suspend the Service entirely, in which
        event we will notify you in advance unless extenuating circumstances,
        such as safety or security concerns, prevent us from doing so.
      </p>

      <p>
        You may terminate your account at any time, for any reason, by sending
        an email to{' '}
        <a
          aria-expanded="false"
          href="mailto:munity-dev@18techs.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          munity-dev@18techs.com
        </a>
        .
      </p>

      <p>
        mUnity may terminate your account at any time without notice if it
        believes that you have violated this Agreement. Upon such termination,
        you will not be entitled to any refund for purchases. After your account
        is terminated, this Agreement will terminate, except for the provisions
        that will still apply to you and mUnity regardless of termination.
      </p>
      <h2>5. Safety; Your Interactions with Other Members.</h2>
      <p>
        You agree to treat other users in a courteous and respectful manner,
        both on and off our Services. mUnity is not responsible for the conduct
        of any member on or off of the Service. You agree to use caution in all
        interactions with other members, particularly if you decide to
        communicate off the Service or meet in person.At no point should mUnity
        or any service member request your financial information (for example,
        your credit card or bank account information), or wire or otherwise send
        money to other members. You are solely responsible for your interactions
        with other members of mUnity and the community. mUnity does not conduct
        research, inquiries, background checks or investigations on its members
        for criminal or legal statuses.
      </p>

      <p>
        mUnity reserves the right to provide assistance and any information to
        local, regional, state, federal or national authorities in the event of
        activity that infringes on the safety of its members.
      </p>
      <h2>6. Rights mUnity Grants You.</h2>
      <p>
        mUnity grants you a personal, worldwide, royalty-free, non-assignable,
        non-exclusive, revocable, and non-sublicensable license to access and
        use the Service. This license is for the sole purpose of letting you use
        and enjoy the Service’s benefits as intended by mUnity and permitted by
        this Agreement. This license and any authorization to access the Service
        are automatically revoked in the event that you do any of the following:
      </p>

      <ul>
        <li>
          use the Service or any content contained in the Service for any
          commercial purposes without our written consent
        </li>
        <li>
          copy, modify, transmit, create any derivative works from, make use of,
          or reproduce in any way any copyrighted material, images, trademarks,
          trade names, service marks, or other intellectual property, content or
          proprietary information accessible through the Service without
          mUnity’s prior written consent
        </li>
        <li>
          express or imply that any statements you make are endorsed by mUnity
        </li>
        <li>
          use any robot, bot, spider, crawler, scraper, site search/retrieval
          application, proxy or other manual or automatic device, method or
          process to access, retrieve, index, “data mine,” or in any way
          reproduce or circumvent the navigational structure or presentation of
          the Service or its contents
        </li>
        <li>
          use the Service in any way that could interfere with, disrupt or
          negatively affect the Service or the servers or networks connected to
          the Service
        </li>
        <li>
          upload viruses or other malicious code or otherwise compromise the
          security of the Service
        </li>
        <li>
          forge headers or otherwise manipulate identifiers in order to disguise
          the origin of any information transmitted to or through the Service
        </li>
        <li>
          “frame” or “mirror” any part of the Service without mUnity’s prior
          written authorization
        </li>
        <li>
          use meta tags or code or other devices containing any reference to
          mUnity or the Service (or any trademark, trade name, service mark,
          logo or slogan of mUnity) to direct any person to any other website
          for any purpose
        </li>
        <li>
          modify, adapt, sublicense, translate, sell, reverse engineer,
          decipher, decompile or otherwise disassemble any portion of the
          Service, or cause others to do so
        </li>
        <li>
          use or develop any third-party applications that interact with the
          Service or other members’ Content or information without our written
          consent
        </li>
        <li>
          use, access, or publish the mUnity application programming interface
          without our written consent
        </li>
        <li>
          probe, scan or test the vulnerability of our Service or any system or
          network
        </li>
        <li>
          encourage, promote, or agree to engage in any activity that violates
          this Agreement
        </li>
      </ul>

      <p>
        mUnity may investigate and take any available legal action in response
        to illegal and / or unauthorized uses of the Service, including
        termination of your account.
      </p>

      <p>
        Any software that we provide you may automatically download and install
        upgrades, updates, or other new features. You may be able to adjust
        these automatic downloads through your device’s settings.
      </p>

      <h2>7. Rights you Grant mUnity.</h2>

      <p>
        By creating an account, you grant to mUnity a worldwide, transferable,
        sub-licensable, royalty-free, right and license to host, store, use,
        copy, display, reproduce, adapt, edit, publish, modify, reformat,
        incorporate into other works, advertise, distribute, and otherwise make
        available to the general public information you authorize us to access
        from third parties such as Facebook, Google, or Apple, as well as any
        information you post, upload, display or otherwise make available
        (collectively, “post”) on the Service or transmit to other members
        (collectively, “Content”). mUnity’s license to your Content shall be
        non-exclusive, except that mUnity’s license shall be exclusive with
        respect to derivative works created through use of the Service. For
        example, mUnity would have an exclusive license to screenshots of the
        Service that include your Content. In addition, so that mUnity can
        prevent the use of your Content outside of the Service, you authorize
        mUnity to act on your behalf with respect to infringing uses of your
        Content taken from the Service by other members or third parties. Our
        license to your Content is subject to your rights under applicable law
        (for example laws regarding personal data protection to the extent any
        Content contains personal information as defined by those laws) and is
        for the limited purpose of operating, developing, providing, and
        improving the Service and researching and developing new ones. You agree
        that any Content you place or that you authorize us to place on the
        Service may be viewed by other members and may be viewed by any person
        visiting or participating in the Service (such as individuals who may
        receive shared Content from other mUnity members).
      </p>

      <p>
        You agree that all information that you submit upon creation of your
        account is accurate and truthful and you have the right to post the
        Content on the Service and grant the license to mUnity above.
      </p>

      <p>
        You understand and agree that we may monitor or review any Content you
        post as part of the Service. We may delete any Content, in whole or in
        part, that in our sole judgment violates this Agreement or may harm the
        reputation of the Service.
      </p>

      <p>
        You agree that mUnity may access, store, and disclose your account
        information and Content if required to do so by law, by performing its
        agreement with you, or in a good faith belief that such access, storage
        or disclosure satisfies a legitimate interest, including to: (i) comply
        with legal process; (ii) enforce the Agreement; (iii) respond to claims
        that any Content violates the rights of third parties; (iv) respond to
        your requests for customer service; or (v) protect the rights, property
        or personal safety of the Company or any other person.
      </p>
      <h2>8. Community Rules.</h2>

      <p>By using the Service, you agree that you will not:</p>

      <ul>
        <li>
          use the Service for any purpose that is illegal or prohibited by this
          Agreement
        </li>
        <li> use the Service for any harmful or nefarious purpose</li>
        <li> use the Service in order to damage mUnity</li>
        <li> violate our Community Guidelines, as updated from time to time</li>
        <li>
          spam or solicit money or other items of value from another member,
          whether as a gift, loan, or other form of compensation, or otherwise
          defraud any members
        </li>
        <li>
          impersonate any person or entity or post any images of another person
          without his or her permission
        </li>
        <li>
          bully, “stalk,” intimidate, assault, harass, mistreat or defame, or
          otherwise mistreat any person
        </li>
        <li>
          post any Content that violates or infringes anyone’s rights, including
          rights of publicity, privacy, copyright, trademark or other
          intellectual property or contract right
        </li>
        <li>
          post any Content that is hate speech, threatening, sexually explicit
          or pornographic; incites violence; or contains nudity or graphic or
          gratuitous violence
        </li>
        <li>
          post any Content that promotes racism, bigotry, hatred or physical
          harm of any kind against any group or individual
        </li>
        <li>
          solicit passwords for any purpose, or personal identifying information
          for commercial or unlawful purposes from other users or disseminate
          another person’s personal information without his or her permission
        </li>
        <li>
          use another user’s account, share an account with another user, or
          maintain more than one account
        </li>
        <li>
          misrepresent your identity, age, current or previous positions,
          qualifications, or affiliations with a person or entity
        </li>
        <li>
          create another account if we have already terminated your account,
          unless you have our permission
        </li>
      </ul>

      <p>
        mUnity reserves the right to investigate and/or terminate your account
        without prior notice if you have violated this Agreement, misused the
        Service or behaved in a way that mUnity regards as inappropriate or
        unlawful, including actions or communications that occur on or off the
        Service. In the event that you violate these rules or our Community
        Guidelines, your authorization to use the Service will be automatically
        revoked.
      </p>

      <h2>9. Other Members’ Content.</h2>

      <p>
        Although mUnity reserves the right to review and remove Content that
        violates this Agreement, such Content is the sole responsibility of the
        member who posts it, and mUnity cannot guarantee that all Content will
        comply with this Agreement. If you see Content on the Service that
        violates this Agreement, please report it within the Service or via our
        contact form.
      </p>

      <h2>10. Disclaimers.</h2>

      <p>
        mUnity provides the service on an “AS IS” and “AS AVAILABLE” basis and
        to the extent permitted by applicable law, grants no warranties or
        guarantees of any kind, whether expressed or implied, statutory or
        otherwise with respect to the service (including all content therein),
        including, without limitation, any implied warranties or satisfactory
        quality, merchantability, fitness for a particular purpose or
        non-infringement.
      </p>

      <p>mUnity does not represent or warrant that the service will be:</p>

      <ul>
        <li>Uninterrupted, secure or error free;</li>
        <li>
          Any defects or errors in the service will be discovered or corrected
        </li>
        <li>
          That any content or information you obtain on or through the service
          will be accurate or appropriate for your purposes
        </li>
      </ul>

      <p>
        mUnity disclaims and assumes no responsibility for any conduct of you or
        any other member, on or off the service.
      </p>
      <h2>11. Limitation of Liability.</h2>
      <p>
        To the fullest extent permitted by applicable law, in no event shall
        mUnity, its affiliates, employees, licensors or service providers be
        liable for any indirect, consequential, exemplary, incidental, special,
        punitive, fixed, or enhanced damages, including, without limitation,
        loss of profits, whether incurred directly or indirectly, or any loss of
        data, use, goodwill, or other intangible losses, resulting from:
      </p>

      <ul>
        <li>
          your access to or use of or inability to access or use the service
        </li>
        <li>
          the conduct or content of any members or third parties on or through
          any of our websites or in connection with the service
        </li>
        <li>
          any unauthorized access, use or alteration of your content, even if
          mUnity has been advised at any time of the possibility of such damages
        </li>
      </ul>

      <p>
        The damages limitation set forth in the immediately preceding sentence
        applies:
      </p>

      <li>
        regardless of the ground upon which liability is based (whether default,
        contract, tort, statute, or otherwise)
      </li>
      <li> irrespective of the type of breach of obligations</li>
      <li> with respect to all events, the service, and this agreement</li>

      <p>
        The limitation of liability provisions set forth in this section 11
        shall apply even if your remedies under this agreement fail with respect
        to their essential purpose.
      </p>

      <p>
        Some jurisdictions do not allow the exclusion or limitation of certain
        damages, so some or all of the exclusions and limitations in this
        section may not apply to you.
      </p>
      <h2>12. Indemnity by You.</h2>
      <p>
        You agree, to the extent permitted under applicable law, to indemnify,
        defend and hold harmless mUnity, our affiliates, and their and our
        respective officers, directors, agents, and employees from and against
        any and all complaints, demands, claims, damages, losses, costs,
        liabilities and expenses, including attorney’s fees, due to, arising out
        of, or relating in any way to your access to or use of the Service, your
        Content, or your breach of this Agreement.
      </p>
      <h2>13. Survivability of the Agreement</h2>
      <p>
        This Agreement, which includes the
        <a href="https://munity.us/privacy">Privacy Policy</a> and any terms
        disclosed to you during your usage of the Service, contains the entire
        agreement between you and mUnity regarding your relationship with mUnity
        and the use of the Service. If any provision of this Agreement is held
        invalid, the remainder of this Agreement shall continue in full force
        and effect. The failure of mUnity to exercise or enforce any right or
        provision of this Agreement shall not constitute a waiver of such right
        or provision. You agree that your mUnity account is non-transferable. No
        agency, partnership, joint venture, fiduciary or other special
        relationship or employment is created as a result of this Agreement and
        you may not make any representations on behalf of or bind mUnity in any
        manner.
      </p>
      <h2>14. Contact</h2>
      <p>
        To contact us, please email{' '}
        <a
          aria-expanded="false"
          href="mailto:munity-dev@18techs.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          munity-dev@18techs.com
        </a>
        .
      </p>
      <br />
      <br />
    </main>
  );
}
