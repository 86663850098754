import { Route, Routes } from 'react-router-dom';
import App from './App';
import Privacy from './routes/privacy';
import Terms from './routes/tos';
import PublicEventInvite from './routes/public-event-invite';

export default function RouterComponent() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="tos" element={<Terms />} />
      <Route path="events/:id" element={<PublicEventInvite />} />
      <Route path="*" element={<App />} />
    </Routes>
  );
}
