import axios from 'axios';
import { useContext, useState } from 'react';
import { createContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import styles from './general.module.css';

export const BaseUrlContext = createContext(
  process.env.REACT_APP_API_URL || 'http://localhost:3000'
);

function useGetEvent(eventId?: string) {
  const [searchParams] = useSearchParams();
  const baseUrl = useContext(BaseUrlContext);

  return useQuery(['events', eventId], async () => {
    const jwt = searchParams.get('token');

    if (!eventId) {
      throw new Error('Invalid event id');
    }

    const url = `${baseUrl}/events/${eventId}/external${
      jwt ? `?token=${jwt}` : ''
    }`;

    const { data } = await axios.get(url);

    return data;
  });
}

function usePostEventReply(eventId?: string) {
  const [searchParams] = useSearchParams();
  const baseUrl = useContext(BaseUrlContext);

  return useMutation(async (reply: 'accepted' | 'rejected') => {
    const jwt = searchParams.get('token');

    if (!eventId) {
      throw new Error('Invalid event id');
    }

    const url = `${baseUrl}/events/${eventId}/external/reply${
      jwt ? `?token=${jwt}` : ''
    }`;

    const { data } = await axios.post(url, { status: reply });

    return data;
  });
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export default function PublicEventInvite() {
  const params = useParams();
  const {
    status: getEventStatus,
    data: eventData,
    error: getEventError,
  } = useGetEvent(params.id);
  const { mutate: postReply } = usePostEventReply(params.id);

  const [confirmStatus, setConfirmStatus] = useState(false);

  const date = new Date(eventData?.date);

  return (
    <main className={styles.background}>
      <header className={styles.logo}>
        <img src="/munity-logo.svg" alt="mUnity" />
      </header>
      <section className={styles.content}>
        {getEventStatus === 'loading' ? (
          'Loading...'
        ) : getEventStatus === 'error' ? (
          <span className="error">
            Error: {(getEventError as Error).message}
          </span>
        ) : !eventData ? (
          `Cannot get event ${params.id}`
        ) : (
          <>
            <div className={styles.section}>
              <img
                className={styles.logoIcon}
                src="/munity-logo-icon.svg"
                alt="munity logo, a drawing of people connecting together"
              />

              <h1 data-testid="title">{eventData.name}</h1>

              <div className={styles.timeBox}>
                <p>
                  {months[date.getMonth()]} {date.getDate()},{' '}
                  {date.getFullYear()}
                </p>
                <p>
                  {days[date.getDay()]} @ {date.getHours()}:{date.getMinutes()}
                </p>
              </div>

              <p className={styles.description}>
                {eventData.description ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus elit libero, tincidunt id lacus sit amet, condimentum euismod mi. Vivamus tincidunt ullamcorper enim pretium accumsan. Vestibulum dapibus eros '}
              </p>

              <p className={styles.locationName}>
                {eventData.locationName || "mUnity's place"}
              </p>
            </div>

            <div className={styles.section}>
              {!eventData.public ? (
                <button
                  className={[
                    styles.confirmButton,
                    ...(confirmStatus ? [styles.confirmed] : []),
                  ].join(' ')}
                  onClick={() => {
                    if (confirmStatus) {
                      postReply('rejected');
                    } else {
                      postReply('accepted');
                    }
                    setConfirmStatus(!confirmStatus);
                  }}
                  data-testid="action"
                ></button>
              ) : null}

              <div className={styles.topics}>
                {eventData.topics.map((topic: any) => (
                  <span key={topic._id} className={styles.topic}>
                    #{topic.value}
                  </span>
                ))}
              </div>
            </div>

            <div className={[styles.banner, styles.section].join(' ')}>
              <img src="/event-banner.png" alt="Scenario with a microphone" />
            </div>
          </>
        )}
      </section>
      <footer className={styles.footer}>
        <a href="https://play.google.com/store/apps/details?id=link.munity.app">
          <img src="/google-play.svg" alt="Get it on Google Play" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=link.munity.app">
          <img src="/apple-store.svg" alt="Download on the App Store" />
        </a>
      </footer>
    </main>
  );
}
